<template>
  <div>
    <public-donation-info></public-donation-info>
    <school-detail
      v-if="getCampaignType === 'district' && !getDonationPageType"
    ></school-detail>
    <wizfit-video-progress
      v-if="
        getDonationPageType.type === 'student' &&
        this.$route.matched[0].path.substring(1) !== 'parent'
      "
    ></wizfit-video-progress>
    <video-section
      v-if="this.$route.matched[0].path.substring(1) === 'parent'"
    ></video-section>
    <prizes-won v-if="getDonationPageType.type === 'student' "></prizes-won>
    <!--&& this.campaign_id!=117-->
    <student-card
      v-if="this.$route.matched[0].path.substring(1) === 'parent'"
    ></student-card>
    <v-container v-if="this.$route.matched[0].path.substring(1) === 'parent'">
      <v-row>
        <v-col cols="12" sm="8">
          <!--<event-and-timeline></event-and-timeline>-->
          <intro-video-pdp></intro-video-pdp>
        </v-col>
      </v-row>
    </v-container>
    <recent-donation
      v-if="
        getDonationPageType.type === 'student' ||
        getDonationPageType.type === 'teacher'
      "
    ></recent-donation>
    <sponsors-detail></sponsors-detail>
    <wizfit-gallery></wizfit-gallery>
    <leaderboard-table></leaderboard-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "PublicDonationPage",
  components: {
    PublicDonationInfo: () => import("./PublicDonationInfo/PublicDonationInfo"),
    LeaderboardTable: () =>
      import(
        "../../components/PublicDonationPages/LeaderboardTable/LeaderboardTable"
      ),
    SponsorsDetail: () =>
      import("../../components/PublicDonationPages/Sponsors/SponsorsDetail"),
    SchoolDetail: () => import("./SchoolDetaill/SchoolDetail"),
    WizfitGallery: () => import("./WizfitGallery/WizfitGallery"),
    RecentDonation: () => import("./RecentDonation/RecentDonation"),
    PrizesWon: () => import("./PrizesWon/PrizesWon"),
    WizfitVideoProgress: () =>
      import("./WizfitVideoProgress/WizfitVideoProgress"),
    StudentCard: () => import("./StudentCards/StudentCards"),
    VideoSection: () =>
      import("@/components/PublicDonationPages/VideoSection/VideoSection"),
    // EventAndTimeline: () =>
    //   import("./PublicDonationInfo/DonationInfoCard/EventTimeline"),
    IntroVideoPdp:()=>import("@/components/PublicDonationPages/IntroVideoPdp/IntroVideoPdp")
  },
  data() {
    return {};
  },
  created() {},
  computed: {
    ...mapGetters({
      getCampaign: "publicDonationPage/getCampaign",
    }),
    getCampaignType() {
      return this.getCampaign.campaign_detail.campaign_type;
    },
    getDonationPageType() {
      return this.getCampaign.type;
    },
  },
  watch: {},
  methods: {},
  mounted() {
  this.campaign_id=this.getCampaign.campaign_detail.id

  
 // alert(this.campaign_id)
 },
};
</script>

<style scoped>
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
}
</style>
